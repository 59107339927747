import * as React from 'react'
import { FaSpaceShuttle, FaCogs } from 'react-icons/fa'
import { CallIconButton } from './call-button'

interface StrategyProps extends React.HTMLProps<HTMLDivElement> {
  pageTitle: React.ReactNode
  pageSubTitle: React.ReactNode
  textOne: React.ReactNode
  textTwo: React.ReactNode
  textThree: React.ReactNode
}

export const Strategy: React.FC<StrategyProps> = ({
  pageTitle,
  pageSubTitle,
  textOne,
  textTwo,
  textThree,
  ...props
}) => {
  return (
    <section className={'py-20'}>
      <div className={'container text-center mx-auto px-4 2xl:px-60'}>
        <h2 className={'mb-2.5 text-4xl font-medium'}>{pageTitle}</h2>
        <p>{pageSubTitle}</p>

        <div
          className={
            'mt-12 block md:flex md:justify-between space-x-0 md:space-x-10 space-y-10 md:space-y-0'
          }
        >
          <Service
            icon={<CallIconButton action={'call'} />}
            title={'Comunícate'}
            desc={textOne}
          />
          <Service
            icon={
              <FaSpaceShuttle className={'text-green-500 text-6xl mx-auto'} />
            }
            title={'Desplazamiento'}
            desc={textTwo}
          />
          <Service
            icon={<FaCogs className={'text-blue-500 text-6xl mx-auto'} />}
            title={'Solución'}
            desc={textThree}
          />
        </div>
      </div>
    </section>
  )
}

interface ServiceProps {
  icon: React.ReactNode
  title: React.ReactNode
  desc: React.ReactNode
}

export const Service: React.FC<ServiceProps> = ({
  icon,
  title,
  desc,
  ...props
}) => {
  return (
    <div
      className={
        'bg-white flex-1 scale-105 transition-all text-center border border-gray-200 rounded-lg pt-10 px-3.5 pb-8'
      }
    >
      {icon}
      <h3 className={'font-semibold text-xl mb-3.5 mt-6'}>{title}</h3>
      <p className={'leading-8'}>{desc}</p>
    </div>
  )
}
