import * as React from 'react'
import { FaSearch } from 'react-icons/fa'
import loadable from '@loadable/component'
import debounce from 'debounce'
import {
  trackCustomEvent,
  CustomEventArgs,
} from 'gatsby-plugin-google-analytics'
import { Ping } from './ping'
import { Product } from './store-product'

const SearchResult = loadable(() =>
  import('./store-input').then(({ SearchResult }) => SearchResult),
)

export const Store = () => {
  const [search, setSearch] = React.useState('')
  const [hasPing, setHasPing] = React.useState(true)

  const trackEventDeb = React.useMemo(() => {
    return debounce((value: CustomEventArgs) => {
      if (!value) {
        return
      }

      trackCustomEvent(value)
    }, 300)
  }, [])

  return (
    <div id={'store'} className={'mb-5 bg-white rounded-md shadow-md pt-2'}>
      <div
        className={
          'mx-2 mb-2 px-2 relative flex items-center rounded-md border border-transparent focus-within:border-cyan-300'
        }
      >
        <input
          value={search}
          className={`py-1 w-full flex-[1] outline-none`}
          placeholder="Busca el producto por su nombre o marca: Pegacor, Taurus."
          onFocus={() => setHasPing(false)}
          onChange={e => {
            const value = e.target.value

            setSearch(value)

            trackEventDeb({
              category: 'search',
              action: 'typing',
              label: value,
            })
          }}
        />
        {hasPing && <Ping className={'absolute top-0 left-[5px]'} />}
        <FaSearch
          className={
            'text-gray-400 absolute right-[10px] top-[calc(50%_-_8px)] pointer-events-none'
          }
        />
      </div>
      {search && <SearchResult search={search} />}
      {!search && <Suggestions />}
    </div>
  )
}

interface SuggestionsProps {
  title?: string
}

const db: {
  id: string
  name: string
  priceRange: number[]
  priority: number
  aliases?: string | null
  description?: string | null
}[] = [
  {
    id: '1',
    name: '"T"  galvanizada  1/2',
    priceRange: [2264, 3167],
    priority: 0,
  },
  {
    id: '799',
    name: 'Union galvanizada 3/8',
    priceRange: [2186, 2695],
    priority: 0,
  },
  {
    id: '256',
    name: 'Codo galvanizado 1/2',
    priceRange: [1964, 2263],
    priority: 0,
  },
  {
    id: '814',
    name: 'Pegante Multipega 270 gramos',
    priceRange: [4753, 6148],
    description:
      'This is a example for a description, should fount items with: "qwerty"',
    priority: 0,
  },
  {
    id: '88888',
    name: 'Servicio de cerrajería',
    priceRange: [60000, 80000],
    priority: 0,
  },
  {
    id: '88889',
    name: 'Cambio de guardas',
    priceRange: [20000, 90000],
    priority: 0,
  },
]

const Suggestions = ({ title }: SuggestionsProps) => {
  return (
    <ul className={'bg-white rounde'}>
      <li className={'px-4 py-2 border-t border-gray-200 hover:bg-gray-50'}>
        <h3 className={'font-bold text-gray-600 text-sm'}>
          {title || 'Más buscados'}
        </h3>
      </li>
      {db.slice(0, 6).map(product => (
        <Product
          key={product.name}
          name={product.name}
          aliases={product.description}
          priceRange={product.priceRange}
        />
      ))}
    </ul>
  )
}
