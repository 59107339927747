import * as React from 'react'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { copFormat } from '../lib/numbers'
import { LeftIcon } from './button-icon'
import { MainCard } from './main-card'

export interface ProductProps {
  name: string
  priceRange: number[]
  description?: string | null
  aliases?: string | null
}

export const Product: React.FC<ProductProps> = ({
  name,
  aliases,
  description,
  priceRange,
}) => {
  const [contactVisible, setContactVisible] = React.useState(false)

  return (
    <li className={'px-4 py-2 border-t border-gray-200 hover:bg-gray-50'}>
      <div className={'flex justify-between align-center'}>
        <div>
          <span className={'block font-semibold'}>
            {name}
            {!!description && (
              <span className={'text-sm font-light text-gray-700'}>
                {' '}
                {description}
              </span>
            )}
          </span>
          <span className={'block text-gray-500'}>
            {copFormat(priceRange[0].toString())}
            {' - '}
            {copFormat(priceRange[1].toString())}
          </span>
        </div>
        <div>
          <button
            type="button"
            className={'btn btn-blue font-light text-sm'}
            style={{ fontWeight: '400' }}
            onClick={() => {
              setContactVisible(v => !v)
            }}
          >
            <LeftIcon>+</LeftIcon>
            Conseguir
          </button>
        </div>
      </div>
      {contactVisible && (
        <div className={'fixed inset-0 z-30 flex justify-center items-center '}>
          <div
            className={'absolute inset-0 bg-gray-800 opacity-70'}
            onClick={() => {
              setContactVisible(false)

              trackCustomEvent({
                category: 'product',
                action: 'click',
                label: name,
              })
            }}
          />
          <div>
            <MainCard hideBooking minHeight={'auto'} />
          </div>
        </div>
      )}
    </li>
  )
}
